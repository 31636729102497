
.App {

  font-family: 'Almarai', sans-serif;
font-family: 'Cairo', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.login-form {
  max-width: 400px;
  margin: 0 auto;
  border: 1px solid #ddd;
  padding: 20px;
  background-color: #f9f9f9;
}

.login-form h1 {
  text-align: center;
  margin-bottom: 20px;
}

.login-form .avatar {
  text-align: center;
  margin-bottom: 20px;
}

.login-form .avatar img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.table-bi{
margin-bottom: 30px !important;
background: #fffffffb !important;
border-radius: 50px !important;
text-decoration: none !important;
}
.input-shwo-form{
background-color: #126ac9e6;

}
 .navbar{
  background-color: #126ac9e6;

 }
.input-shw-form  input {
 
  background: #99a2b496;
  border: none;
  text-align: center;
  height: 40px;
  border-radius: 8px;
  color: rgb(248, 246, 246);
  font-weight: 800;
  
}

.bg-create-form
{
background: #26464c48;

}

.text_breadcrumbs{
  text-decoration: none !important;
}
chart-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}